import { render, staticRenderFns } from "./ViewCandidates.vue?vue&type=template&id=215ae69a&scoped=true&"
import script from "./ViewCandidates.vue?vue&type=script&lang=ts&"
export * from "./ViewCandidates.vue?vue&type=script&lang=ts&"
import style0 from "./ViewCandidates.vue?vue&type=style&index=0&id=215ae69a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "215ae69a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VChip,VCol,VDialog,VIcon,VPagination,VRow,VSkeletonLoader})
