






































































import Vue, { PropType } from "vue";
import { CandidateStatusOption } from "@/interfaces/recruiter/admin/all_candidates";
import { mapGetters } from "vuex";
import { CANDIDATE_FILTER } from "@/store/modules/recruiter/constants";
import { AllCandidatesFilterCount } from "@/store/modules/recruiter/interfaces";

export default Vue.extend({
  name: "CandidateHeader",
  computed: {
    CandidateStatusOption() {
      return CandidateStatusOption;
    },
    ...mapGetters("recruiter", {
      candidate_filter: CANDIDATE_FILTER
    })
  },
  props: {
    all_candidates: {
      type: Array as PropType<AllCandidatesFilterCount[]>
    }
  },
  methods: {
    get_count_of_candidate(status: CandidateStatusOption) {
      return (
        this.all_candidates.find((candidate) => candidate.status === status)
          ?.count || 0
      );
    }
  }
});
