

































































































































































































































import Vue from "vue";
import { ViewCandidates } from "@/interfaces/recruiter/candidates/view_candidates/view_candidates";
import ViewCandidatesBody from "@/components/recruiter/candidates/view_candidates/ViewCandidatesBody.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  ADVANCE_SEARCH,
  ALL_CANDIDATES,
  CANDIDATE_FILTER,
  FETCH_MATCHING_CANDIDATES,
  GET_FAVORITE_CANDIDATES,
  JOBS_POSTED_BY_RECRUITER,
  MATCHING_CANDIDATES,
  SEARCH_TEXT,
  SELECTED_JOB,
  TOP_JOBS_WITH_CANDIDATES,
  VIEW_CANDIDATES_DYNAMICALLY,
  VIEW_CANDIDATES_PAGINATION
} from "@/store/modules/recruiter/constants";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import {
  AllCandidatesFilterCount,
  MatchingCandidatesStatus,
  SearchCandidatesPayload,
  SearchedCandidate,
  ViewCandidatesPagination
} from "@/store/modules/recruiter/interfaces";
import AutoCompleteJobSearch from "@/components/shared/jobs/AutoCompleteJobSearch.vue";
import AutoCompleteUserSearch from "@/components/shared/AutoCompleteUserSearch.vue";
import AutoCompleteSkillSearch from "@/components/shared/jobs/AutoCompleteSkillSearch.vue";
import { SearchJobObject } from "@/interfaces/shared/jobs/auto_complete_job_search";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { GetFavoriteCandidates } from "@/interfaces/responses/favorite_candidates";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import CandidateHeader from "@/components/recruiter/candidates/CandidateHeader.vue";
import { CandidateStatusOption } from "@/interfaces/recruiter/admin/all_candidates";
import ExtraDetails from "@/components/shared/view_job_details/ExtraDetails.vue";
import { capitalize_text } from "@/utils/global";

export default Vue.extend({
  /**
   * Activated this page
   *  1: User click on view more button (matching candidates) from dashboard
   *  2: User click on count number from job listing page
   *  3: User click on candidate tab from sidebar
   */
  name: "ViewCandidates",
  components: {
    ViewCandidatesBody,
    AutoCompleteJobSearch,
    CandidateHeader,
    ExtraDetails
  },
  data(): ViewCandidates & {
    searched_user_ids: number[];
    dialog: boolean;
    default_job: SearchJobObject | null;
    all_candidates_count: AllCandidatesFilterCount[];
    job_items: SearchJobObject[];
  } {
    return {
      loading: false,
      dynamic_data: false,
      active_tab: "onsite",
      matching_candidates: [],
      job_with_candidate: null,
      advance_search_text: null,
      search_job_id: null,
      job_id: null,
      pagination: 1,
      user_ids: [],
      searched_user_ids: [],
      dialog: true,
      default_job: null,
      all_candidates_count: [],
      job_items: []
    };
  },
  async created() {
    if (this.selected_job) {
      this.default_job = this.selected_job;
      this.dialog = false;
      this.job_id = this.selected_job.job_id;
      // await this.searched_job(this.selected_job);
    } else {
      this.set_candidate_filter(MatchingCandidatesStatus.SHORTLISTED);
      this.fetch_job_items();
    }
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("recruiter", {
      view_candidates_dynamically: VIEW_CANDIDATES_DYNAMICALLY,
      get_matching_candidates: FETCH_MATCHING_CANDIDATES,
      top_posted_jobs: TOP_JOBS_WITH_CANDIDATES,
      get_all_candidates: ALL_CANDIDATES,
      view_candidates_pagination: VIEW_CANDIDATES_PAGINATION,
      get_search_text: SEARCH_TEXT,
      candidate_filter: CANDIDATE_FILTER,
      selected_job: SELECTED_JOB
    }),
    CandidateStatusOption() {
      return CandidateStatusOption;
    }
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    ...mapActions("recruiter", {
      fetch_matching_candidates: FETCH_MATCHING_CANDIDATES,
      fetch_top_jobs_with_candidates: JOBS_POSTED_BY_RECRUITER,
      fetch_matching_candidates_by_id: MATCHING_CANDIDATES,
      fetch_favorite_candidates_by_job: GET_FAVORITE_CANDIDATES,
      advance_search: ADVANCE_SEARCH
    }),
    ...mapMutations("recruiter", {
      set_candidates_view_dynamically: VIEW_CANDIDATES_DYNAMICALLY,
      set_all_candidates: ALL_CANDIDATES,
      set_view_candidates_pagination: VIEW_CANDIDATES_PAGINATION,
      set_search_text: SEARCH_TEXT,
      set_candidate_filter: CANDIDATE_FILTER,
      set_selected_job_by_hr: SELECTED_JOB
    }),
    /**
     * Function to processing search job filter
     */
    async searched_job(value: SearchJobObject) {
      // Update local variable => hold the job id
      this.search_job_id = value ? value.job_id : null;
      if (value)
        this.set_selected_job_by_hr({
          job_id: value.job_id,
          text: value.text,
          sector: value.sector,
          experience: value.experience,
          employment_type: value.employment_type
        });

      if (value) {
        this.job_id = value ? value.job_id : null;
        const payload = {
          get_status_count: true,
          job_ids: [value.job_id]
        };
        const _all_candidates = await this.fetch_matching_candidates(payload);
        this.all_candidates_count = _all_candidates?.results || [];
      }
      // Update data set in order to view candidates
      this.set_candidates_view_dynamically({
        active: !!value,
        loading: false,
        job_id: value ? value.job_id : null,
        job_title: value ? value.text : "",
        skill: this.view_candidates_pagination.searched_skill_filter,
        user_ids: []
      });
      if (!value) {
        this.set_selected_job_by_hr(null);
        this.all_candidates_count = [];
        this.get_all_candidates.results = [];
        this.matching_candidates = [];
        this.pagination = 1;
        this.job_id = null;
        this.set_candidate_filter(MatchingCandidatesStatus.SHORTLISTED);
        if (this.job_items && this.job_items.length > 0) {
          this.job_items = [...this.job_items];
        } else this.fetch_job_items();
      } else await this.fetch_candidates_with_pagination(1);
    },
    reset_data() {
      this.matching_candidates = [];
      this.advance_search_text = null;
      this.search_job_id = null;
      this.job_with_candidate = null;
      this.loading = false;
      this.pagination = 1;
      this.job_id = null;
      this.user_ids = [];
      this.searched_user_ids = [];
      this.set_search_text("");
    },
    async process_favorite_candidates(
      data: SearchedCandidate[],
      job_id: number
    ) {
      // Fetching favorite candidates against job
      const favorites = await this.fetch_favorite_candidates_by_job(job_id);
      const res = data.map((obj: SearchedCandidate) => ({
        ...obj,
        is_favorite: false
      }));
      // If favorite candidates found => process true if found
      if (favorites?.length > 0) {
        return res.map((obj1: SearchedCandidate) => {
          let obj2 = favorites.find(
            (obj2: GetFavoriteCandidates) =>
              obj1.candidate_id === obj2.candidate_id
          );
          if (obj2) {
            obj1.is_favorite = true;
          }
          return obj1;
        });
      } else return res;
    },
    /**
     * Function to fetch candidates with pagination
     */
    async fetch_candidates_with_pagination(page = 1, limit = 12) {
      this.loading = true;
      if (this.selected_job && this.selected_job.job_id) {
        this.search_job_id = this.selected_job.job_id;
      }
      const payload: SearchCandidatesPayload = {
        page: page - 1,
        limit_per_page: limit,
        n: 20,
        user_ids: [],
        role: "Individual",
        status: this.candidate_filter
      };
      // If job id is selected in filter => set else set null
      if (this.search_job_id) {
        payload.job_ids = [this.search_job_id];
        this.job_id = this.search_job_id;
      } else {
        this.job_id = null;
        this.job_with_candidate = null;
      }
      // If skill search filter is set => set skill name
      if (this.view_candidates_pagination.searched_skill_filter) {
        payload.skill_name =
          this.view_candidates_pagination.searched_skill_filter;
      }

      // Fetching all candidates
      const _all_candidates = await this.fetch_matching_candidates(payload);
      let all_candidates = _all_candidates;
      if (this.job_id) {
        // Set candidates whose score is greater than 20%
        all_candidates = _all_candidates.results.filter(
          (candidate: Candidates.Candidates) => candidate.score >= 0.2
        );
      }
      // If candidates found
      if (
        (all_candidates &&
          all_candidates.results &&
          all_candidates.results?.length > 0) ||
        all_candidates?.length > 0
      ) {
        // Set filter count to -1 => if not set already
        if (!all_candidates.filtered_count) all_candidates.filtered_count = -1;
        this.set_all_candidates(all_candidates); // Setting in store
        // If job filter is applied
        if (this.job_id) {
          // Fetching favorite candidates
          const res = await this.process_favorite_candidates(
            this.job_id
              ? this.get_all_candidates
              : this.get_all_candidates.results,
            this.job_id
          );
          // Use to hold all candidates when job filter is applied
          this.job_with_candidate = {
            candidates_loading: false,
            matching_candidates: res
          };
          // Fetching matching candidates with favorites
          this.matching_candidates =
            this.job_with_candidate.matching_candidates;
        }
        // If job filter not applied
        else {
          this.matching_candidates = this.get_all_candidates.results;
        }
      }
      // If fetching all candidates failed
      else {
        this.set_all_candidates({ total: 0, results: [], filtered_count: -1 });
        this.reset_data();
        // If job filter is applied => reset
        if (this.search_job_id) {
          const ref = this.$refs.autocomplete_job as InstanceType<
            typeof AutoCompleteJobSearch
          >;
          ref.reset_state();
          this.search_job_id = null;
          this.job_id = null;
        }
        // If search filter is applied => reset
        if (this.view_candidates_pagination.searched_skill_filter) {
          const ref = this.$refs.autocomplete_skill as InstanceType<
            typeof AutoCompleteSkillSearch
          >;
          ref.reset_state();
          this.set_view_candidates_pagination({
            pagination: this.view_candidates_pagination.pagination,
            searched_skill_filter: ""
          });
        }
        // If users filter active
        if (this.user_ids?.length) {
          const ref = this.$refs.autocomplete_user as InstanceType<
            typeof AutoCompleteUserSearch
          >;
          ref.reset_state();
        }
        // If dynamic view set
        if (this.view_candidates_dynamically.active) {
          this.set_candidates_view_dynamically({
            active: false,
            loading: false,
            job_id: null,
            job_title: "",
            skill: "",
            user_ids: []
          });
        }
      }
      this.loading = false;
    },
    update_candidate_filter(status: string) {
      if (this.selected_job) {
        this.set_candidate_filter(status);
        this.fetch_candidates_with_pagination();
      }
    },
    async fetch_job_items() {
      await this.fetch_top_jobs_with_candidates({
        page: 0,
        limit_per_page: 5,
        dashboard: true
      });
      if (
        this.top_posted_jobs.results &&
        this.top_posted_jobs.results.length > 0
      )
        this.job_items = await this.top_posted_jobs.results.map(
          (job: any) =>
            ({
              job_id: job.jid,
              text: capitalize_text(job.job_title),
              sector: job.sector,
              experience: job.experience,
              employment_type: job.employment_type
            } as SearchJobObject)
        );
    }
  },
  beforeDestroy() {
    if (this.$route.name !== "candidate-profile") {
      const payload: ViewCandidatesPagination = {
        pagination: 1,
        searched_skill_filter: ""
      };
      this.set_view_candidates_pagination(payload);
    }
  }
});
