var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-options d-flex align-center"},[_c('v-btn',{staticClass:"base-submit-button filter-options-btn",class:{
      active: _vm.candidate_filter === _vm.CandidateStatusOption.SHORTLISTED
    },on:{"click":function($event){return _vm.$emit('update_candidate_filter', _vm.CandidateStatusOption.SHORTLISTED)}}},[_vm._v(" "+_vm._s(_vm.$t("status.shortlisted"))+" ("+_vm._s(_vm.get_count_of_candidate(_vm.CandidateStatusOption.SHORTLISTED))+") ")]),_c('v-btn',{staticClass:"base-submit-button filter-options-btn",class:{
      active: _vm.candidate_filter === _vm.CandidateStatusOption.INTERVIEW
    },on:{"click":function($event){return _vm.$emit('update_candidate_filter', _vm.CandidateStatusOption.INTERVIEW)}}},[_vm._v(" "+_vm._s(_vm.$t("status.interviewed"))+" ("+_vm._s(_vm.get_count_of_candidate(_vm.CandidateStatusOption.INTERVIEW))+") ")]),_c('v-btn',{staticClass:"base-submit-button filter-options-btn",class:{
      active: _vm.candidate_filter === _vm.CandidateStatusOption.ONBOARDING
    },on:{"click":function($event){return _vm.$emit('update_candidate_filter', _vm.CandidateStatusOption.ONBOARDING)}}},[_vm._v(" "+_vm._s(_vm.$t("status.onboarding"))+" ("+_vm._s(_vm.get_count_of_candidate(_vm.CandidateStatusOption.ONBOARDING))+") ")]),_c('v-btn',{staticClass:"base-submit-button filter-options-btn",class:{
      active: _vm.candidate_filter === _vm.CandidateStatusOption.JOINED
    },on:{"click":function($event){return _vm.$emit('update_candidate_filter', _vm.CandidateStatusOption.JOINED)}}},[_vm._v(" "+_vm._s(_vm.$t("status.joined"))+" ("+_vm._s(_vm.get_count_of_candidate(_vm.CandidateStatusOption.JOINED))+") ")]),_c('v-btn',{staticClass:"base-submit-button filter-options-btn",class:{
      active: _vm.candidate_filter === _vm.CandidateStatusOption.REJECTED,
      rejected: _vm.candidate_filter === _vm.CandidateStatusOption.REJECTED
    },on:{"click":function($event){return _vm.$emit('update_candidate_filter', _vm.CandidateStatusOption.REJECTED)}}},[_vm._v(" "+_vm._s(_vm.$t("status.rejected"))+" ("+_vm._s(_vm.get_count_of_candidate(_vm.CandidateStatusOption.REJECTED))+") ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }